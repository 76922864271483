window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    // TODO: Enable consent tracking when it will needed
    // ... be sure to confirm either the country selector poping should be displayed before/after consent tracking popin
    // processInclude(require('base/components/consentTracking')); 
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('base/components/toolTip'));
});
require('./Zoom/zoom');
require('slick-carousel/slick/slick');
require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('select2/dist/js/select2');
require('jquery-mousewheel/jquery.mousewheel');
require('malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min');


