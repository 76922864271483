'use strict';

var scrollAnimate = require('base/components/scrollAnimate');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
 
function displayMessage(data, button, form) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if (form.find('.invalid-feedback').length === 0) {
        form.append(
           '<div class="invalid-feedback"></div>'
        );
    }

    form.find('.invalid-feedback')
      .append('<div class="status-valid-' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        form.find('.invalid-feedback').remove();
        button.removeAttr('disabled');
    }, 3000);
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('.subscribe-email').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var form = $(this).closest('form');
        var button = $(this);
        var emailId = form.find('input[name=hpEmailSignUp]').val();
         $.spinner().stop();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId
            },
            success: function (data) {
                displayMessage(data, button, form);
            },
            error: function (err) {
                displayMessage(err, button, form);
            }
        });
    });

    $('.dropup').on('click', function(e) {
        var dropdownMenu = $(this).children(".dropdown-menu");
        dropdownMenu.toggleClass("show");

    })
};
$('.js-custom-select').select2({
    minimumResultsForSearch: -1,
});